import {
  CLEAR_ERRORS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  LOAD_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  NEW_PASSWORD_FAIL,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
} from "../constants/authConstants";
import {
  APIKEY_CREATION_FAIL,
  APIKEY_CREATION_REQUEST,
  APIKEY_CREATION_SUCCESS,
} from "../constants/apiConstants";
import { isUndefined } from "lodash";

const saveTokenInLocalStorage = (token) => {
  localStorage.setItem("token", JSON.stringify(token));
};
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
const isUserAuthenticated = () => {
  const token = getTokenFromLocalStorage();
  console.log(token, !isUndefined(token));
  return !isUndefined(token);
};

export function authReducer(
  state = {
    user: {},
    loading: false,
    error: null,
    isAuthenticated: isUserAuthenticated(),
  },
  action
) {
  // console.log({ state });
  const { type, payload } = action;
  switch (type) {
    case LOGIN_REQUEST:
      return { loading: true, isAuthenticated: false };
    case LOGIN_SUCCESS:
      const { user, token } = payload;
      saveTokenInLocalStorage(token);
      return { loading: false, isAuthenticated: true, user };
    case LOGIN_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
        error: payload,
      };
    case REGISTER_REQUEST:
      return { loading: true, isAuthenticated: false };
    case REGISTER_SUCCESS:
      return { loading: false, isAuthenticated: true, user: payload };
    case REGISTER_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
        error: payload,
      };
    case LOAD_USER_REQUEST:
      return { loading: true, isAuthenticated: isUserAuthenticated() };
    case LOAD_USER_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        user: payload,
        error: null,
      };
    case LOAD_USER_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
        error: payload,
      };
    case LOGOUT_SUCCESS:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
        error: null,
      };

    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case APIKEY_CREATION_REQUEST:
      return { ...state, loading: true };

    case APIKEY_CREATION_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload?.user,
      };
    case APIKEY_CREATION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case UPDATE_PROFILE_REQUEST:
      return { ...state, loading: true, error: null };
    case UPDATE_PROFILE_SUCCESS:
      return { ...state, loading: false, user: payload };

    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case UPDATE_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };
    case UPDATE_PASSWORD_SUCCESS:
      return { ...state, loading: false, token: payload?.token };
    case UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case FORGOT_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, loading: false };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case NEW_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };
    case NEW_PASSWORD_SUCCESS:
      saveTokenInLocalStorage(payload.token);
      return {
        ...state,
        loading: false,
        user: payload.user,
        isAuthenticated: true,
      };
    case NEW_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
