import React, { useState } from "react";
import "./Header.css";
import { arePropsEqual, formatCloudinaryUrl } from "../../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/pics/logo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import HeaderDropdown from "../dropdown/headerDropdown/HeaderDropdown";
import { SettingsIcon } from "../../assets/icons/SettingsIcon";
import DataIcon from "../../assets/icons/DataIcon";
import LogoutIcon from "../../assets/icons/LogoutIcon";
import { logoutUser } from "../../actions/authActions";
import AvatarIcon from "../../icons/AvatarIcon";

const Header = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [isDropMenuExpanded, setIsDropMenuExpanded] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const domain = window.location.hostname;

  const options = [
    {
      label: "API",
      optionClickFct: () => navigate(`/${user.name.toLowerCase().trim()}/api`),
      icon: React.createElement(DataIcon),
    },
    {
      label: "Settings",
      icon: React.createElement(SettingsIcon),
      optionClickFct: () =>
        navigate(`/${user.name.toLowerCase().trim()}/settings`),
    },
    {
      label: "Logout",
      optionClickFct: () => {
        dispatch(logoutUser());
        navigate("/");
      },
      icon: React.createElement(LogoutIcon),
    },
  ];

  return (
    <header className="header">
      <Link to={"/"}>
        <LazyLoadImage
          effect="blur"
          src={logo}
          alt="website logo"
          className="header-logo"
        />
      </Link>
      <div className="header-api-auth">
        <a
          className="header-api"
          title="get cars data by using our API"
          href={`http://${domain}/api/docs`}
          target="_blank"
          rel="noopener noreferrer" // Adds security for external links
        >
          Docs
        </a>
        {isAuthenticated ? (
          <div
            className="header-user"
            onClick={() => {
              setIsDropMenuExpanded(!isDropMenuExpanded);
            }}
          >
            {user?.avatar?.url ? (
              <img
                src={formatCloudinaryUrl(user?.avatar?.url, 54, 54)}
                alt="user avatar"
                className="header-user-pic"
              />
            ) : (
              <AvatarIcon className="header-user-pic" />
            )}
            <span className="header-user-name">{user?.name}</span>
            {isDropMenuExpanded && (
              <HeaderDropdown
                options={options}
                showDropDownFct={setIsDropMenuExpanded}
              />
            )}
          </div>
        ) : (
          <Link className="header-login" title="login user" to={"/login"}>
            Login
          </Link>
        )}
      </div>
    </header>
  );
};

export default React.memo(Header, arePropsEqual);
